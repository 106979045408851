import { Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import HelpIcon from '@mui/icons-material/Help';
import * as Yup from 'yup';

export const tuyaDeviceValidation = Yup.object({
  appIdentifier: Yup.string()
    .required('Veuillez renseigner votre ID virtuel')
    .matches(/^BF.*$/i, {
      message: 'L\'ID virtuel commence par "bf"',
      excludeEmptyString: true
    })
    .matches(/^[a-zA-Z0-9]{22}$/, {
      message: "L'ID virtuel contient exactement 22 caractères",
      excludeEmptyString: true
    })
});

const TuyaDevice = (validationSchema) => {
  return (
    <>
      <Typography variant="h4" gutterBottom align={'left'} paddingLeft={1} paddingBottom={1}>
        <b>Connectez la passerelle</b>
      </Typography>

      <Typography variant="p" align={'left'} paddingLeft={1} display={'block'} lineHeight={'20px'}>
        Saisissez ci-dessous l'ID virtuel <u>de votre passerelle</u>.
      </Typography>

      <Stack direction="row" paddingTop={0.4} paddingBottom={1.2}>
        <Field fullWidth name="appIdentifier" component={TextField} label="ID virtuel*" autoFocus />
      </Stack>

      <Typography variant="p" paddingLeft={1}>
        <a
          href={`/deviceid-tuya`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#002160', fontWeight: 600, fontSize: '15px' }}
          className="link_tutorial"
        >
          <HelpIcon
            style={{
              fontSize: '18px',
              verticalAlign: '-3px',
              marginRight: '2px'
            }}
          />
          Comment trouver mon ID virtuel ?
        </a>
      </Typography>
      <br />
    </>
  );
};

export default TuyaDevice;
