import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { RawCaseButton } from '../../util/buttons';

export const deviceStepInitialValues = {
  appIdentifier: ''
};
const GenericDevice = () => {
  return (
    <>
      <Typography variant="h4" gutterBottom align={'left'} paddingLeft={1}>
        <b>Connectez votre appareil</b>
      </Typography>
      <Typography variant="body1" paragraph align={'left'} paddingLeft={1} paddingBottom={1}>
        Nous allons relier votre appareil, pour automatiser les charges.
      </Typography>
      <ThroughAppID />
      <Box paddingBottom={4} paddingTop={4}>
        <Divider>
          <b>OU</b>
        </Divider>
      </Box>
      <ThroughRedirect />
      <br />
    </>
  );
};

function ThroughAppID() {
  return (
    <Paper
      elevation={0}
      variant={'outlined'}
      style={{ padding: 10, textAlign: 'left', backgroundColor: '#fffffe' }}
    >
      <Typography variant="h5" paddingLeft={1}>
        Noter l’identifiant de votre app
      </Typography>
      <Typography variant="body2" paddingLeft={1}>
        Vous pouvez le trouver sur votre application dans la section settings {'>'} ... {'>'} ...
      </Typography>
      <Stack direction="row">
        <Field fullWidth name="appIdentifier" component={TextField} label="Indentifiant" />
      </Stack>
    </Paper>
  );
}

function ThroughRedirect() {
  return (
    <Paper
      elevation={0}
      variant={'outlined'}
      style={{ padding: 10, textAlign: 'left', backgroundColor: '#fffffe' }}
    >
      <Typography variant="h5" paragraph paddingLeft={1}>
        Connectez vous directement sur [YOUR BRAND]
      </Typography>
      <Stack direction="row" justifyContent={'flex-end'} paddingBottom={'5px'} paddingRight={'5px'}>
        <RawCaseButton variant="contained" color="secondary">
          <b>Se connecter</b>
        </RawCaseButton>
      </Stack>
    </Paper>
  );
}
export default GenericDevice;
