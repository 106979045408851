import { useState, useCallback } from 'react';
import axios from 'axios';
import { getScreenshot, useDevicesPresentParams, useInstallerId, useIsDebugMode } from './misc';
import { isPrmFound } from './enedis';
import { useAuth } from './authentication';

let consentScreenshot = null;

async function _registerSite(
  serverUrl,
  values,
  token,
  email,
  providerName,
  installerId,
  pilotage_system,
  firebase_user_access,
  electric_radiator_present,
  heat_pump_present
) {
  await axios.post(
    `${serverUrl}/register_user_site`,
    {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      address: values.address.trim(),
      postcode: values.postcode.trim(),
      city: values.city.trim(),
      email: email,
      app_identifier: values.appIdentifier?.toUpperCase()?.trim() || null,
      prm: values.prm !== undefined ? values.prm.trim() : null,
      proof_img_base64: consentScreenshot,
      country: 'France',
      provider: providerName,
      installer_id: installerId,
      pilotage_system: pilotage_system,
      firebase_user_access: firebase_user_access,
      electric_radiator_present: electric_radiator_present,
      heat_pump_present: heat_pump_present,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function useTryRegisterSite() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const isDebug = useIsDebugMode();
  const installerId = useInstallerId();
  const devicesPresent = useDevicesPresentParams();

  const tryRegisterSite = useCallback(
    async (serverUrl, providerName, values, prmFound, pilotage_system, firebase_user_access) => {
      setIsLoading(true);

      let isSuccess = undefined;
      let error = null;

      const token = await user?.getIdToken();
      const email = user?.email;

      if (!token && !isDebug) {
        throw new Error('User not logged in!');
      }

      if ('prm' in values && values.prm) {
        prmFound = true;
      }

      // Take screenshot for GDPR, to be saved to GCS
      if (consentScreenshot === null) {
        try {
          consentScreenshot = await getScreenshot(1800);
        } catch (error) {
          console.error('GDPR screenshot failed:', error.message);
        }
      }

      if (prmFound === undefined) {
        try {
          prmFound = await isPrmFound(
            serverUrl,
            token,
            values.firstName,
            values.lastName,
            values.address,
            values.postcode,
            values.city
          );
        } catch (error) {
          console.error(error);
          prmFound = false;
        }
      }

      const electric_radiator_present = devicesPresent['electric_radiator_present'];
      const heat_pump_present = devicesPresent['heat_pump_present'];

      if (prmFound) {
        if (!isDebug) {
          await _registerSite(
            serverUrl,
            values,
            token,
            email,
            providerName,
            installerId,
            pilotage_system,
            firebase_user_access,
            electric_radiator_present,
            heat_pump_present
          );
        } else {
          console.log({
            serverUrl: serverUrl,
            values: values,
            token: token,
            email: email,
            providerName: providerName,
            installerId: installerId,
            pilotage_system: pilotage_system,
            firebase_user_access: firebase_user_access,
            electric_radiator_present: devicesPresent.electricRadiatorPresent,
            heat_pump_present: devicesPresent.heatPumpPresent
          });
        }
        isSuccess = true;
      } else {
        isSuccess = false;
      }
      return { isSuccess, error };
    },
    [user, isDebug, installerId, devicesPresent]
  );

  return { tryRegisterSite, isLoading };
}
