import { createTheme, ThemeProvider } from '@mui/material';
import './css/app.css';
import { AuthProvider } from './util/authentication';
import { Navigate, Route, Routes } from 'react-router-dom';
import { customizeByProvider } from './stepper/customization';
import TermsAndConditions from './pages/terms';
import FindPRMTutorial from './pages/findPRMTutorial';
import { useParams } from 'react-router-dom';
import { CompleteAuth } from './util/authentication';
import PrivacyPolicy from './pages/privacy';
import FindTuyaDeviceIdTutorial from './pages/findTuyaDeviceIdTutorial';

const theme = createTheme({
  palette: {
    primary: {
      main: '#002160'
    },
    secondary: {
      main: '#24DBC9',
      contrastText: '#ffffff'
    }
  }
});

const Registration1: React.FC<{ serverUrl: string }> = ({ serverUrl }) => {
  const { provider } = useParams();

  if (!provider) {
    throw new Error('Provider does not exist.');
  }

  const { registration1, layout } = customizeByProvider(provider, serverUrl);
  return <>{layout(registration1)}</>;
};

const Registration2: React.FC<{ serverUrl: string }> = ({ serverUrl }) => {
  const { provider } = useParams();

  if (!provider) {
    throw new Error('Provider does not exist.');
  }

  const { registration2, layout } = customizeByProvider(provider, serverUrl);
  return <CompleteAuth layout={layout} continueComponent={registration2} />;
};

function App() {
  const serverUrl = 'https://tilt-api-server-3rihgzpwea-od.a.run.app';
  // const serverUrl = 'http://localhost:8003';

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <div className="app">
          <Routes>
            <Route path="/register/:provider" element={<Registration1 serverUrl={serverUrl} />} />
            <Route
              path="/completeRegistration/:provider"
              element={<Registration2 serverUrl={serverUrl} />}
            />

            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/terms/:provider" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/find-prm" element={<FindPRMTutorial />} />
            <Route path="/deviceid-tuya" element={<FindTuyaDeviceIdTutorial />} />

            {/* Redirections for backward compatibility */}
            <Route path="/register" element={<Navigate to="/register/generic" replace />} />
            <Route
              path="/register-delta-dore"
              element={<Navigate to="/register/delta-dore" replace />}
            />
            <Route
              path="/register-bioenergies"
              element={<Navigate to="/register/bioenergies" replace />}
            />
            <Route path="/register-premium" element={<Navigate to="/register/premium" replace />} />
            <Route path="/register-dhomeco" element={<Navigate to="/register/dhomeco" replace />} />
            <Route
              path="/register-homelior"
              element={<Navigate to="/register/homelior" replace />}
            />
            <Route path="/register-profire" element={<Navigate to="/register/profire" replace />} />
            <Route
              path="/register-renovhabitatandco"
              element={<Navigate to="/register/renovhabitatandco" replace />}
            />
            <Route path="/register-s2ee" element={<Navigate to="/register/s2ee" replace />} />
            <Route path="/register-effy" element={<Navigate to="/register/effy" replace />} />
            <Route path="/register-effy" element={<Navigate to="/register/effy" replace />} />
            <Route
              path="/register-primes-energie"
              element={<Navigate to="/register/primes-energie" replace />}
            />
            <Route
              path="/register-stockdis"
              element={<Navigate to="/register/stockdis" replace />}
            />
          </Routes>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
