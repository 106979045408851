import { Button, Popover, Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import * as Yup from 'yup';
import { required_message } from '../../util/fields';

export const deltaDoreDeviceStepValidation = Yup.object({
  appIdentifier: Yup.string()
    .required(`${required_message} identifiant`)
    .length(6, "L'identifiant comporte 6 caractères")
});

const DeltaDoreDevice = (validationSchema) => {
  const [anchorDevEl, setAnchorDevEl] = React.useState(null);
  const [anchorAppEl, setAnchorAppEl] = React.useState(null);
  const handleDevClick = (event) => {
    setAnchorDevEl(event.currentTarget);
  };

  const handleDevClose = () => {
    setAnchorDevEl(null);
  };

  const handleAppClick = (event) => {
    setAnchorAppEl(event.currentTarget);
  };

  const handleAppClose = () => {
    setAnchorAppEl(null);
  };

  const openDev = Boolean(anchorDevEl);
  const devId = openDev ? 'simple-popover' : undefined;
  const openApp = Boolean(anchorAppEl);
  const appId = openApp ? 'simple-popover' : undefined;
  return (
    <>
      <Typography variant="h4" gutterBottom align={'left'} paddingLeft={1}>
        <b>Connectez votre appareil</b>
      </Typography>
      <Typography variant="h5" paddingLeft={1}>
        Vous pouvez retrouver l'identifiant de votre appareil Tydom:
      </Typography>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ paddingTop: 18 }}
      >
        <Button aria-describedby={devId} variant="text" onClick={handleDevClick}>
          Sur votre appareil
        </Button>
        <Popover
          id={devId}
          open={openDev}
          anchorEl={anchorDevEl}
          onClose={handleDevClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ShowDeviceDiagrams />
        </Popover>
        <Button aria-describedby={appId} variant="text" onClick={handleAppClick}>
          Sur l'app Delta Dore
        </Button>
        <Popover
          id={appId}
          open={openApp}
          anchorEl={anchorAppEl}
          onClose={handleAppClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ShowAppDiagram />
        </Popover>

        <Stack direction="row">
          <Field fullWidth name="appIdentifier" component={TextField} label="Identifiant" />
        </Stack>
      </Stack>

      <br />
    </>
  );
};

function ShowDeviceDiagrams() {
  return (
    <div style={{ padding: 10 }}>
      <Typography variant="h6" paddingLeft={1}>
        Retournez l'appareil et notez les 6 derniers caractères de l'adresse MAC
      </Typography>
      <Stack direction={'row'} spacing={0.1}>
        <figure>
          <img
            src="/providers/delta-dore/tydom_1.0_mac.png"
            alt="Tydom 1.0 Diagram"
            style={{ height: '200px' }}
          />
          <figcaption>Tydom 1.0</figcaption>
        </figure>
        <figure>
          <img
            src="/providers/delta-dore/tydom_2.0_mac.png"
            alt="Tydom 2.0 Diagram"
            style={{ height: '200px' }}
          />
          <figcaption>Tydom 2.0</figcaption>
        </figure>
        <figure>
          <img
            src="/providers/delta-dore/tydom_home_mac.png"
            alt="Tydom Home Diagram"
            style={{ height: '200px' }}
          />
          <figcaption>Tydom Home</figcaption>
        </figure>
      </Stack>
    </div>
  );
}

function ShowAppDiagram() {
  return (
    <div style={{ padding: 10 }}>
      <Typography variant="h6" paddingLeft={1}>
        Allez dans les paramètres...
      </Typography>

      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        <img
          src={'/providers/delta-dore/screenshot_mock.png'}
          alt="Comment trouver l'addresse MAC"
        />
      </div>
    </div>
  );
}

export default DeltaDoreDevice;
